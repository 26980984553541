/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Select,
  Divider,
  Input,
  Modal,
  message,
  Empty,
  Alert,
  Spin,
  Checkbox,
  List,
} from "antd";
import {
  useAppDispatch,
  useTypedSelector,
  PRESET_DATA_TYPES,
  EventSchema,
} from "../../store";
import { makeBid, AuctionItem, removeAutoBid } from "./auctionsSlice";
import { useFirestoreConnect, isEmpty } from "react-redux-firebase";
import AuctionItemDisplay from "./AuctionItemDisplay";
import {
  CloseCircleOutlined,
  FileImageTwoTone,
  LoadingOutlined,
  MehOutlined,
} from "@ant-design/icons";
import AutoBidPopoverButton from "./components/AutoBidPopoverButton";
import Title from "antd/lib/typography/Title";
import BidPopoverButton from "./components/BidPopoverButton";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import { LazyImage } from "react-lazy-images";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const { Search } = Input;

const loadingAnimation2 = keyframes`
  0 {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ${loadingAnimation2} 1.4s ease infinite;
`;

const LoadingCards = ({ eventDetails }: { eventDetails: EventSchema }) => {
  return (
    <React.Fragment>
      <Col xs={24}>
        <Alert
          type="warning"
          message={`Items are currently hidden. Check back soon or when the auction is scheduled to go live ${moment(
            eventDetails.start_date
          ).fromNow()}`}
        />
      </Col>
      {[...new Array(6)].map((_, idx) => (
        <Col md={12} xs={24} lg={8} key={`placeholder-${idx}`}>
          <Card style={{ width: "100%", marginTop: 16 }} loading={true}>
            <Card.Meta
              avatar={
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              }
              title=""
              description=""
            />
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};
const AuctionIndex = ({
  eventId,
  currentUserId,
  eventDetails,
}: {
  eventId: string;
  currentUserId: null | string;
  eventDetails: null | EventSchema;
}) => {
  const dispatch = useAppDispatch();
  const [visibleItemId, setVisibleItemId] = useState("");
  const [filter, setFilter] = useState<null | string>(null);
  const [categoryFilter, setCategoryFilter] = useState<null | string>(null);
  const [showWithBidOnly, setShowWithBidOnly] = useState(false);

  useFirestoreConnect([
    {
      collection: `events`,
      doc: eventId,
      subcollections: [{ collection: "items" }],
      storeAs: "items",
    },
    ...((currentUserId
      ? [
          {
            collection: "events",
            doc: eventId,
            subcollections: [
              {
                collection: "bids",
                doc: currentUserId,
                subcollections: [{ collection: "items" }],
              },
            ],
            storeAs: `myBids`,
          },
        ]
      : []) as any),
  ]);

  const auctionItems: AuctionItem[] = useTypedSelector(
    (state) => state.firestore.ordered.items || []
  );
  const auth = useTypedSelector((state) => state.firebase.auth);
  const user = useTypedSelector((state) => state.firebase.profile);
  const myBidsByItemId = useTypedSelector(
    (state) => state.firestore?.data?.myBids || {}
  ) as any;

  const auctionItemsFetched =
    useTypedSelector((state) => state.firestore.status.requested["items"]) ||
    false;
  const auctionItemsLoading =
    useTypedSelector((state) => state.firestore.status.requesting["items"]) ||
    false;

  const isAuthenticated = !isEmpty(auth);
  const canPlaceBids =
    isAuthenticated && user.stripePaymentMethodId && eventDetails?.live;

  const filteredAuctionItems = auctionItems.filter((item) => {
    let match = true;
    if (filter) {
      match = item.title.toLowerCase().includes(filter.toLowerCase());
    } else if (categoryFilter) {
      match = item.category?.some((category) =>
        category.toLowerCase().includes(categoryFilter.toLowerCase())
      );
    }

    if (showWithBidOnly) {
      match = match && myBidsByItemId[item.id];
    }

    return match;
  });

  const clearAllFilters = () => {
    setFilter(null);
    setCategoryFilter(null);
    setShowWithBidOnly(false);
  };

  const hasAnyFilters = filter || categoryFilter || showWithBidOnly;

  const wonItems = filteredAuctionItems.filter(
    (item) => item.currentWinner && item.currentWinner === auth.uid
  );

  const isAuctionComplete =
    eventDetails && !eventDetails.live && eventDetails.ended_at;

  return (
    <div className="site-card-wrapper">
      <Row gutter={[16, 16]}>
        {eventDetails?.description && (
          <Col xs={24} style={{ background: "#fff", padding: 20 }}>
            <div style={{ textAlign: "center" }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/reins-auction.appspot.com/o/marketing%2Fhoedown-logo.jpg?alt=media&token=4bed3e58-590f-4ddf-889a-1c0953557015"
                alt="hoedown logo"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: eventDetails.description || "",
              }}
            ></div>
          </Col>
        )}
      </Row>
      {isAuctionComplete ? null : (
        <React.Fragment>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <Select
                style={{ width: "100%" }}
                placeholder="All Categories"
                allowClear
                options={[
                  ...[{ label: "Show all categories", value: "" }],
                  ...PRESET_DATA_TYPES.categories,
                ]}
                onSelect={(value) => {
                  setCategoryFilter(value as string);
                }}
                {...(categoryFilter ? { value: categoryFilter } : {})}
                onChange={(value) => setCategoryFilter((value || "") as string)}
              />
            </Col>

            <Col
              xs={24}
              md={{ span: 8, offset: 8 }}
              style={{ textAlign: "right" }}
            >
              <Search
                style={{ width: "100%" }}
                placeholder="Search"
                onChange={(event) => setFilter(event?.currentTarget.value)}
                onSearch={(text) => setFilter(text)}
                {...(filter ? { value: filter } : {})}
                allowClear
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div>
                Showing {filteredAuctionItems.length} of {auctionItems.length}{" "}
                items
              </div>
              <div>
                {hasAnyFilters && (
                  <Button
                    type="link"
                    onClick={() => clearAllFilters()}
                    style={{ padding: 0 }}
                  >
                    Clear filters
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              Only show item's I've bid on?{" "}
              <Checkbox
                checked={showWithBidOnly}
                onChange={({ target: { checked } }) =>
                  setShowWithBidOnly(checked)
                }
              />
            </Col>
          </Row>
        </React.Fragment>
      )}

      <Divider />
      <Row gutter={[16, 16]}>
        {!auctionItemsFetched ||
          (auctionItemsLoading && (
            <div style={{ textAlign: "center" }}>
              <Spin
                indicator={<LoadingOutlined spin style={{ fontSize: 100 }} />}
              />
            </div>
          ))}

        {filteredAuctionItems.length === 0 && auctionItemsFetched ? (
          <Col style={{ margin: "0 auto", textAlign: "center" }} span={24}>
            <Empty
              style={{ height: 180 }}
              image={<MehOutlined style={{ fontSize: 140 }} />}
              description=""
            />
            <Title level={4}>
              Oh no! It looks like there are no items matching the given
              criteria.
              <br />
              Would you like to clear your filters and show all items?
            </Title>

            <Button
              onClick={() => {
                clearAllFilters();
              }}
              type="primary"
              size="large"
              style={{ margin: 30 }}
            >
              Show all items
            </Button>
          </Col>
        ) : filteredAuctionItems.length > 0 &&
          eventDetails?.hide_items_until_live &&
          !eventDetails?.live ? (
          <LoadingCards eventDetails={eventDetails} />
        ) : (
          <React.Fragment>
            {eventDetails && !eventDetails.live && !eventDetails.started_at && (
              <Col xs={24}>
                <Alert
                  type="warning"
                  message={`Bidding is currently disabled and is scheduled to go live ${moment(
                    eventDetails.start_date
                  ).fromNow()}, although it may be started earlier. Check back soon!`}
                />
              </Col>
            )}
            {isAuctionComplete && (
              <Col xs={24}>
                <Alert
                  type="warning"
                  message={`The auction has ended. Thank you very much for your participation${
                    wonItems.length > 0
                      ? ` and congratulations on winning ${wonItems.length} items!`
                      : "."
                  }`}
                />
              </Col>
            )}
            <List
              grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
              dataSource={isAuctionComplete ? wonItems : filteredAuctionItems}
              pagination={
                isAuctionComplete
                  ? undefined
                  : {
                      pageSize: 12,
                      position: "both",
                      showSizeChanger: false,
                      style: { margin: "10px 0" },
                    }
              }
              locale={{
                emptyText:
                  isAuctionComplete && wonItems.length === 0 ? (
                    <h3 style={{ padding: 20 }}>
                      Unfortunately, you didn't win any items. Better luck next
                      time!
                    </h3>
                  ) : undefined,
              }}
              style={{ width: "100%" }}
              renderItem={(item) => (
                <List.Item>
                  <Card bordered={false}>
                    <div
                      style={{
                        height: 200,
                        overflow: "hidden",
                        textAlign: "center",
                      }}
                    >
                      {item.images?.length ? (
                        <div style={{ maxWidth: "100%", position: "relative" }}>
                          <LazyImage
                            src={item.images[0].url}
                            alt={item.images[0].name}
                            actual={({ imageProps }: any) => (
                              <img
                                {...imageProps}
                                style={{ maxWidth: "100%" }}
                                onClick={() => setVisibleItemId(item.id)}
                              />
                            )}
                            placeholder={({ ref }: any) => (
                              <Placeholder
                                ref={ref}
                                onClick={() => setVisibleItemId(item.id)}
                              />
                            )}
                            error={() => (
                              <p>There was an error fetching this image :(</p>
                            )}
                          />
                        </div>
                      ) : (
                        <Empty
                          description=""
                          image={
                            <FileImageTwoTone
                              style={{ fontSize: 90 }}
                              twoToneColor="#eee"
                              onClick={() => setVisibleItemId(item.id)}
                            />
                          }
                        />
                      )}
                    </div>
                    <h3>{item.title}</h3>
                    <h4>Donated by: {item.donated_by || "N/A"}</h4>
                    <Row>
                      <Col span={10}>Value</Col>
                      <Col span={14}>
                        {item.mark_as_priceless
                          ? "Priceless"
                          : (item.value || "").toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>Current Bid</Col>
                      <Col span={14}>
                        {(item.currentAmount || 0).toLocaleString("en-us", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}{" "}
                        {item.currentAmount < item.starting_bid ||
                          ((item.currentAmount || 0) === 0 && (
                            <i>
                              <span>
                                (Starting bid:{" "}
                                {(item.starting_bid || 0).toLocaleString(
                                  "en-us",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }
                                )}
                                )
                              </span>
                            </i>
                          ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>Current Winner</Col>
                      <Col span={14}>
                        {item.currentAmount === 0 ||
                          (!item.currentAmount && "Be the first bidder")}
                        {isAuctionComplete &&
                        item.currentWinner === currentUserId
                          ? "You won!"
                          : item.currentWinner === currentUserId
                          ? "You are currently winning"
                          : "Someone else is winning, bid now!"}
                      </Col>
                    </Row>
                    {myBidsByItemId[item.id] &&
                    myBidsByItemId[item.id].autobid ? (
                      <Row
                        style={{
                          border: "1px solid #ccc",
                          textAlign: "center",
                          margin: "20px 0px 0px 0px",
                          padding: "10px 5px",
                          background: "#f7fdf8",
                        }}
                      >
                        <Col xs={24}>
                          {item.currentAmount > myBidsByItemId[item.id].amount
                            ? `You had autobidding set to ${myBidsByItemId[
                                item.id
                              ].amount.toLocaleString("en-us", {
                                style: "currency",
                                currency: "USD",
                              })} but the current amount is now higher.`
                            : `You are autobidding on this item up to ${myBidsByItemId[
                                item.id
                              ].amount.toLocaleString("en-us", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`}
                        </Col>
                        <Col xs={24} style={{ marginTop: 10 }}>
                          <Button
                            type="primary"
                            danger
                            onClick={async () => {
                              const resultAction = await dispatch(
                                removeAutoBid({
                                  event_id: eventId,
                                  item_id: item.id,
                                })
                              );
                              if (removeAutoBid.fulfilled.match(resultAction)) {
                                message.success(
                                  "Autobidding has been removed for this item"
                                );
                              }
                            }}
                          >
                            <CloseCircleOutlined /> Remove auto-bid
                          </Button>
                        </Col>
                      </Row>
                    ) : null}

                    <div style={{ textAlign: "center", padding: 20 }}>
                      <div style={{ padding: "0px 0px 20px" }}>
                        <Button
                          size="large"
                          onClick={() => setVisibleItemId(item.id)}
                          type="link"
                        >
                          View Details
                        </Button>
                      </div>
                      {!isAuctionComplete && (
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <BidPopoverButton
                              item={item}
                              disabled={!canPlaceBids}
                              onBid={async ({ amount }) => {
                                const resultAction = await dispatch(
                                  makeBid({
                                    event_id: eventId,
                                    item_id: item.id,
                                    amount,
                                  })
                                );
                                if (makeBid.fulfilled.match(resultAction)) {
                                  if (
                                    resultAction.payload.result
                                      ?.currentWinner !== currentUserId
                                  ) {
                                    message.warning(
                                      "Your bid was placed, but someone immediately outbid you."
                                    );
                                  } else {
                                    message.success(
                                      "Your bid was placed and you are now winning!"
                                    );
                                  }
                                } else {
                                  let knownMessage =
                                    "There was an error, try again.";
                                  if (resultAction.payload) {
                                    knownMessage =
                                      resultAction.payload.message ||
                                      knownMessage;
                                  }
                                  message.error(knownMessage);
                                }
                              }}
                            />
                          </Col>
                          <Col span={12}>
                            <AutoBidPopoverButton
                              item={item}
                              disabled={!canPlaceBids}
                              onBid={async ({ amount }) => {
                                const resultAction = await dispatch(
                                  makeBid({
                                    event_id: eventId,
                                    item_id: item.id,
                                    amount,
                                    autobid: true,
                                  })
                                );
                                if (makeBid.fulfilled.match(resultAction)) {
                                  message.success(
                                    `Autobidding has been set to ${amount}`
                                  );
                                } else {
                                  if (resultAction.payload) {
                                    const knownMessage =
                                      resultAction.payload.message ||
                                      "There was an error, try again.";
                                    message.error(knownMessage);
                                  }
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </React.Fragment>
        )}
      </Row>
      <Modal
        visible={Boolean(visibleItemId)}
        onOk={() => setVisibleItemId("")}
        onCancel={() => setVisibleItemId("")}
        cancelText="Close"
        width={600}
      >
        {visibleItemId ? <AuctionItemDisplay id={visibleItemId} /> : null}
      </Modal>
    </div>
  );
};

export default AuctionIndex;
