import React, { useState, useEffect, useContext, createContext } from "react";

const screenSize = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export type Device = "desktop" | "tablet" | "mobile";

interface Props {
  children?: React.ReactChildren;
}

export const DeviceTypeCtx = createContext<Device>("desktop");

const DeviceTypeProvider: any = ({ children }: Props) => {
  const [device, setDevice] = useState<Device>("desktop");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= screenSize.sm && device !== "mobile") {
        setDevice("mobile");
      } else if (
        window.innerWidth > screenSize.sm &&
        window.innerWidth < screenSize.md &&
        device !== "tablet"
      ) {
        setDevice("tablet");
      } else if (window.innerWidth > screenSize.md && device !== "desktop") {
        setDevice("desktop");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [device]);

  return (
    <DeviceTypeCtx.Provider value={device}>{children}</DeviceTypeCtx.Provider>
  );
};

export default DeviceTypeProvider;
export const useDeviceType = () => useContext(DeviceTypeCtx);

export const DeviceType = ({
  children,
}: {
  children(props: Device): JSX.Element;
}) => {
  const device = useDeviceType();
  return children(device);
};
