import React, { useEffect } from "react";
import "./App.less";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import AuctionIndex from "./features/auction/AuctionIndex";
import { Alert, Col, Layout, Row, Spin } from "antd";
import loadable from "@loadable/component";

import { useTypedSelector } from "./store";
import {
  isEmpty,
  useFirebase,
  isLoaded,
  useFirestoreConnect,
} from "react-redux-firebase";
import { messaging } from ".";
import { LoadingOutlined } from "@ant-design/icons";
import { useDeviceType } from "./utils/DeviceTypeProvider";

const { Footer, Content } = Layout;

const LoginPage = loadable(() => import("./features/auth/LoginPage"));
const ForgotPasswordPage = loadable(
  () => import("./features/auth/forgot-password")
);
const RegisterPage = loadable(() => import("./features/auth/RegisterPage"));
const DonatePage = loadable(() => import("./features/billing/donate-page"));
const DonationThankYouPage = loadable(
  () => import("./features/billing/donate-thanks-page")
);
const PrivateRoute = loadable(() => import("./utils/PrivateRoute"));
const SettingsContainer = loadable(
  () => import("./features/auth/SettingsContainer")
);
const LiveViewTV = loadable(() => import("./features/live/LiveViewTV"));
const AdminContainer = loadable(
  () => import("./features/admin/AdminContainer")
);
const HeaderMobile = loadable(() => import("./features/app/HeaderMobile"));

function App() {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const user = useTypedSelector((state) => state.firebase.profile);
  const currentEventDetails = useTypedSelector(
    (state) => state.firestore.ordered?.currentEvent?.[0]
  );
  const currentEventFetched = useTypedSelector(
    (state) => state.firestore.status?.requested?.currentEvent
  );

  const firebase = useFirebase();
  const location = useLocation();

  const device = useDeviceType();

  useFirestoreConnect([
    {
      collection: `events`,
      where: ["current", "==", true],
      limit: 1,
      storeAs: "currentEvent",
    },
  ]);

  useEffect(() => {
    if (isEmpty(auth) || !isLoaded(user) || !messaging) {
      return;
    }

    if (messaging) {
      messaging
        .requestPermission()
        .then(async function () {
          const token = await (await messaging!.getToken()).trim();
          if (token !== user.notification_token) {
            await firebase.updateProfile({
              notification_token: token,
            });
          }
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });

      navigator.serviceWorker.addEventListener("message", (message) =>
        console.log(message)
      );

      messaging.onTokenRefresh(() => {
        messaging!
          .getToken()
          .then(async (refreshedToken) => {
            console.log("Token refreshed.", refreshedToken);
            if (refreshedToken !== user.notification_token) {
              await firebase.updateProfile({
                notification_token: refreshedToken,
              });
            }
          })
          .catch((err) => {
            console.log("Unable to retrieve refreshed token ", err);
            // showToken('Unable to retrieve refreshed token ', err);
          });
      });
    }
  }, [auth, user, firebase]);

  const missingEvent = currentEventFetched && !currentEventDetails;
  const currentEventId = currentEventDetails?.id;

  if (!isLoaded(auth)) {
    return null;
  }

  const isAuthenticated = !isEmpty(auth);
  const canPlaceBids = isAuthenticated && user.stripePaymentMethodId;

  return (
    <div className="App">
      <Layout className="layout">
        {location.pathname.includes("/live") ? (
          <div
            style={{
              background: "#005500",
              color: "#fff",
              textAlign: "center",
              fontSize: device === "mobile" ? "1.1rem" : "1.8rem",
              padding: 15,
              marginBottom: device === "mobile" ? 10 : 70,
            }}
          >
            Donate now at donate.reinsprogram.org
          </div>
        ) : (
          <HeaderMobile isAuthenticated={!isEmpty(auth)} />
        )}

        <Content
          style={{
            padding: device === "mobile" ? "20px 10px" : "40px 50px",
            textAlign: "left",
          }}
        >
          {!["/register", "/login", "/donate", "/live"].some((path) =>
            location.pathname.includes(path)
          ) ? (
            <Row gutter={[16, 16]}>
              {!isAuthenticated ? (
                <Col xs={24}>
                  <Alert
                    type="warning"
                    message="Please sign in or create an account"
                    showIcon
                    description={
                      <div>
                        You need to be signed in to participate. Would you like
                        to <Link to="/login">sign in now</Link> or{" "}
                        <Link to="/register">create an account</Link>?
                      </div>
                    }
                  />
                </Col>
              ) : !canPlaceBids ? (
                <Col xs={24}>
                  <Alert
                    type="warning"
                    showIcon
                    message={
                      <div>
                        Thank you for registering for the auction! In order to
                        place bids, please{" "}
                        <Link to="/settings/billing">
                          set up your billing profile
                        </Link>{" "}
                        and{" "}
                        <Link to="/settings/profile">
                          update your contact information.
                        </Link>
                        .
                      </div>
                    }
                  />
                </Col>
              ) : null}
            </Row>
          ) : null}

          {!isLoaded(auth) ? null : (
            <Switch>
              <Route
                path="/login/forgot"
                exact
                component={ForgotPasswordPage}
              />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={RegisterPage} />
              <Route exact path="/live/:eventId/tv">
                <LiveViewTV style={{ background: "#1000ff" }} />
              </Route>
              <Route path="/live/:eventId" component={LiveViewTV} />
              <Route
                path="/donate/thanks/:sessionId"
                component={DonationThankYouPage}
              />
              <Route path="/donate">
                <DonatePage
                  currentEventId={
                    missingEvent
                      ? ""
                      : currentEventId !== null
                      ? currentEventId
                      : ""
                  }
                />
              </Route>

              <PrivateRoute path="/admin" roles={["admin"]}>
                <AdminContainer />
              </PrivateRoute>

              <PrivateRoute path="/settings">
                <SettingsContainer />
              </PrivateRoute>

              <Route path="/">
                {currentEventId && isLoaded(auth) && !isEmpty(auth) ? (
                  <AuctionIndex
                    eventId={currentEventId}
                    eventDetails={currentEventDetails}
                    currentUserId={auth.uid}
                  />
                ) : currentEventId && isLoaded(auth) && isEmpty(auth) ? (
                  <AuctionIndex
                    eventId={currentEventId!}
                    eventDetails={currentEventDetails}
                    currentUserId={null}
                  />
                ) : missingEvent ? (
                  <div>There is currently no upcoming event</div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Spin
                      indicator={
                        <LoadingOutlined spin style={{ fontSize: 100 }} />
                      }
                    />
                  </div>
                )}
              </Route>
            </Switch>
          )}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          &copy; {new Date().getFullYear()} REINS Therapeutic Riding Program.
          All Rights Reserved.
          <br />
          REINS is a 501(c)(3) non-profit organization providing therapeutic
          riding in Southern California.
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
