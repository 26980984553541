import * as React from "react";
import { Col, Row, Empty, Card, Statistic } from "antd";
import { FileImageTwoTone } from "@ant-design/icons";
import { useTypedSelector } from "../../store";
import { selectAuctionItemById } from "./auctionsSlice";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

interface Props {
  id: string;
}
const AuctionItem: React.FC<Props> = ({ id }) => {
  const auctionItem = useTypedSelector((state) =>
    selectAuctionItemById(state, id)
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} style={{ marginTop: 15 }}>
        {auctionItem.images?.length >= 1 ? (
          <AutoplaySlider
            style={{ marginBottom: 30 }}
            play={true}
            interval={2000}
          >
            {auctionItem.images.map((image, index) => (
              <div key={`${image}-index`}>
                <img
                  alt={`${image.name}`}
                  src={image.url}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ))}
          </AutoplaySlider>
        ) : (
          <Empty
            description=""
            image={
              <FileImageTwoTone style={{ fontSize: 90 }} twoToneColor="#eee" />
            }
          />
        )}
      </Col>
      <Col span={24}>
        <Card size="small" title="Bidding Information" bordered={false}>
          <Row gutter={[16, 16]}>
            <Col xs={8}>
              <Card>
                <Statistic
                  title="Current Bid"
                  value={Number(auctionItem.currentAmount || 0)}
                  precision={0}
                  prefix="$"
                />
              </Card>
            </Col>
            <Col xs={8}>
              <Card>
                <Statistic
                  title="Next Bid"
                  value={
                    Number(auctionItem.currentAmount || 0) <
                    auctionItem.starting_bid
                      ? auctionItem.starting_bid
                      : auctionItem.currentAmount +
                        (auctionItem.minimum_bid_increment || 5)
                  }
                  precision={0}
                  prefix="$"
                />
              </Card>
            </Col>
            <Col xs={8}>
              <Card>
                <Statistic
                  title="Min. Bid"
                  value={auctionItem.minimum_bid_increment}
                  precision={0}
                  prefix="$"
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card bordered={false} title="Donated by">
          {auctionItem.donated_by || "N/A"}
        </Card>
      </Col>

      <Col span={24}>
        <Card bordered={false} title="Description">
          <div dangerouslySetInnerHTML={{ __html: auctionItem.description }} />
        </Card>
      </Col>
      {/* <Col>{JSON.stringify(auctionItem, null, 2)}</Col> */}
    </Row>
  );
};

export default AuctionItem;
