import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, DefaultThunkExtra } from "../../store";
import { FIREBASE_FUNCTIONS_BASE_URL } from "../../config";

export const makeBid = createAsyncThunk<
  {
    result: {
      currentAmount: number;
      currentWinner: string;
      actionCount: number;
      actions: {
        idx: number;
        uid: string;
        autobid: boolean;
        amount: number;
        createdAt: string;
      }[];
    };
    user: any;
  },
  any,
  {
    extra: DefaultThunkExtra;
    rejectValue: {
      message: string;
    };
  }
>(
  "auctions/bid",
  async (
    { event_id, item_id, amount, autobid = false },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.post(
        `${FIREBASE_FUNCTIONS_BASE_URL}/bids`,
        {
          event_id,
          item_id,
          amount,
          autobid,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

export const removeAutoBid = createAsyncThunk<
  {
    result: {
      currentAmount: number;
      currentWinner: string;
      actionCount: number;
      actions: {
        idx: number;
        uid: string;
        autobid: boolean;
        amount: number;
        createdAt: string;
      }[];
    };
    user: any;
  },
  any,
  {
    extra: DefaultThunkExtra;
    rejectValue: {
      message: string;
    };
  }
>(
  "auctions/removeAutobid",
  async (
    { event_id, item_id },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.post(
        `${FIREBASE_FUNCTIONS_BASE_URL}/bids`,
        {
          event_id,
          item_id,
          autobid: false,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

export const startAuction = createAsyncThunk(
  "auctions/start",
  async (
    { eventId }: { eventId: string },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.post(
        `${FIREBASE_FUNCTIONS_BASE_URL}/auctions/start/${eventId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

export const stopAuction = createAsyncThunk(
  "auctions/stop",
  async (
    { eventId }: { eventId: string },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.delete(
        `${FIREBASE_FUNCTIONS_BASE_URL}/auctions/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

export const generateWinners = createAsyncThunk(
  "auctions/generateWinners",
  async (
    { eventId }: { eventId: string },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.post(
        `${FIREBASE_FUNCTIONS_BASE_URL}/auctions/generate-results/${eventId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

//  "/generate-invoice/:eventId/item/:itemId",
export const generateInvoice = createAsyncThunk(
  "auctions/generateInvoice",
  async (
    { eventId, itemId }: { eventId: string; itemId: string },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.post(
        `${FIREBASE_FUNCTIONS_BASE_URL}/auctions/generate-invoice/${eventId}/item/${itemId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

export const generateInvoices = createAsyncThunk(
  "auctions/generateInvoices",
  async (
    { eventId }: { eventId: string },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const { data } = await axios.post(
        `${FIREBASE_FUNCTIONS_BASE_URL}/auctions/generate-invoices/${eventId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

const slice = createSlice({
  name: "auctions",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(makeBid.fulfilled, (state, action) => {
      console.log("action", action);
    });
  },
});

export default slice.reducer;

export const selectAuctionItemById = (state: RootState, id: string) =>
  (state.firestore.data.items[id] || {}) as AuctionItem;
export const selectAllAuctionItems = (state: RootState) =>
  ((state.firestore.ordered.items || []) as any) as AuctionItem[];
export const selectTotalDonationsFromEventItems = createSelector(
  [selectAllAuctionItems],
  (auctionItems) => {
    const sum = auctionItems.reduce((acc, curr) => {
      acc += curr.currentAmount;
      return acc;
    }, 0);
    return sum;
  }
);
export interface AuctionItem {
  id: string;
  category: string[];
  currentAmount: number;
  currentWinner: string;
  endAt: Date;
  image: string;
  images: AuctionItemImageFile[];
  stars: string[];
  startAt: Date;
  tags: string[];
  title: string;
  description: string;
  value: number;
  starting_bid: number;
  minimum_bid_increment: number;
  buy_now_price: number;
  active: boolean;
  mark_as_priceless: boolean;
  donated_by: string;
  actionCount: number;
}

export type AuctionItemImageFile = {
  url: string;
  uid: string;
  name: string;
  status: string;
};
