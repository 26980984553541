import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import "firebase/messaging";

import { BrowserRouter } from "react-router-dom";

import {
  FIREBASE_APIKEY,
  FIREBASE_APPID,
  FIREBASE_AUTHDOMAIN,
  FIREBASE_DATABASEURL,
  FIREBASE_MEASUREMENTID,
  FIREBASE_MESSAGINGSENDERID,
  FIREBASE_PROJECTID,
  FIREBASE_STORAGEBUCKET,
  FIREBASE_FUNCTIONS_BASE_URL,
  FIREBASE_MESSAGING_PUBLIC_VAPID_KEY,
} from "./config";
import DeviceTypeProvider from "./utils/DeviceTypeProvider";

const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  databaseURL: FIREBASE_DATABASEURL,
  projectId: FIREBASE_PROJECTID,
  storageBucket: FIREBASE_STORAGEBUCKET,
  messagingSenderId: FIREBASE_MESSAGINGSENDERID,
  appId: FIREBASE_APPID,
  measurementId: FIREBASE_MEASUREMENTID,
};

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

if (FIREBASE_FUNCTIONS_BASE_URL?.includes("localhost")) {
  db.settings({
    host: "localhost:8081",
    ssl: false,
  });
}

firebase.analytics
  .isSupported()
  .then(() => {
    console.log("intializing analytics");
    firebase.analytics();
  })
  .catch((err) => {
    console.error("Analytics not supported");
  });

firebase.storage();
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

// const isDev = ["localhost", "preview"].some((segment) =>
//   window.location.host.includes(segment)
// );
// console.log(
//   "using",
//   isDev ? "./firebase-messaging-dev-sw.js" : "./firebase-messaging-prod-sw.js"
// );

// navigator.serviceWorker
//   .register(
//     isDev ? "firebase-messaging-dev-sw.js" : "firebase-messaging-prod-sw.js"
//   )
//   .then((registration) => {
//     console.log("reg", registration);
//     messaging.useServiceWorker(registration);
//   });
// if (FIREBASE_MESSAGING_PUBLIC_VAPID_KEY) {
//   messaging.usePublicVapidKey(FIREBASE_MESSAGING_PUBLIC_VAPID_KEY);
// }

export { messaging };

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <DeviceTypeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DeviceTypeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
