import React, { forwardRef, useEffect, useState } from "react";
import {
  Popover,
  Form as AntForm,
  Popconfirm,
  Button as AntButton,
  InputNumber,
} from "antd";
import { AuctionItem } from "../auctionsSlice";
import { useForm, Controller } from "react-hook-form";
import { ButtonProps } from "antd/lib/button";

type AutobidFields = {
  amount: string;
};

const doubleCurrentBidOrActualValue = (item: AuctionItem) => {
  if (item.currentAmount) {
    if (item.currentAmount > item.value) {
      return item.currentAmount + item.minimum_bid_increment * 10;
    }
  }
  return item.value;
};

const Button = forwardRef((props: ButtonProps, ref) => {
  return <AntButton size="large" block {...props} />;
});

const AutoBidPopoverButton = ({
  item,
  onBid,
  disabled = false,
}: {
  item: AuctionItem;
  onBid: (values: AutobidFields) => void;
  disabled: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const getStartingBidOrPlusMinimumIncrement = (item: AuctionItem) => {
    if ((item.currentAmount || 0) < item.starting_bid) {
      return item.starting_bid;
    }
    return item.currentAmount + item.minimum_bid_increment || 10;
  };

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<AutobidFields>({
    defaultValues: {
      amount: String(doubleCurrentBidOrActualValue(item)),
    },
  });

  useEffect(() => {
    if (!isDirty) {
      reset({ amount: String(getStartingBidOrPlusMinimumIncrement(item)) });
    }
  }, [reset, item, isDirty]);

  const handleSetAutobid = handleSubmit((values) => {
    onBid(values);
    reset({ amount: String(getStartingBidOrPlusMinimumIncrement(item)) });
  });

  const formValues = watch();

  return disabled ? (
    <Button loading={loading} disabled={true}>
      Auto-bid
    </Button>
  ) : (
    <Popover
      title="Configure Auto-bidding"
      trigger="click"
      onVisibleChange={(value) => setShowPopover(value)}
      visible={showPopover}
      content={
        <div>
          <AntForm.Item
            // hasFeedback
            validateStatus={errors.amount ? "error" : "success"}
            label="Max bid"
          >
            <Controller
              as={
                <InputNumber
                  step={item.minimum_bid_increment || 5}
                  defaultValue={getStartingBidOrPlusMinimumIncrement(item)}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                  min={
                    item.starting_bid > item.currentAmount
                      ? item.starting_bid
                      : item.currentAmount
                  }
                />
              }
              control={control}
              name="amount"
              rules={{
                required: "You must enter an amount",
                min: getStartingBidOrPlusMinimumIncrement(item),
              }}
            />
          </AntForm.Item>
          <Popconfirm
            placement="top"
            title={
              <div style={{ maxWidth: 200 }}>
                You are going to automatically bid in increments of $
                {item.minimum_bid_increment} until you reach your maximum bid of{" "}
                {Number(formValues.amount).toLocaleString("en-us", {
                  style: "currency",
                  currency: "USD",
                })}
                . Is this correct?
              </div>
            }
            onConfirm={async (e) => {
              setLoading(true);
              try {
                await handleSetAutobid();
                setShowPopover(false);
              } catch (err) {}
              setLoading(false);
            }}
            okText="Confirm"
            cancelText="Cancel"
            style={{
              maxWidth: 100,
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <Button loading={loading} disabled={disabled}>
              Set Auto-bid
            </Button>
          </Popconfirm>
        </div>
      }
    >
      <Button loading={loading} disabled={disabled}>
        Auto-bid
      </Button>
    </Popover>
  );
};

export default AutoBidPopoverButton;
